export default {
  // ? ==========================
  // ? =======  Layout  =========
  // ? ==========================

  navigation: {},

  footer: {},

  ctaWidget: {},

  pageHero: {
    '.title': {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      margin: '2rem auto 0rem',
      textTransform: 'capitalize',
      ':after': {
        content: "''",
        borderRadius: '252px 55px 104px 20px / 34px',
        background:
          'linear-gradient(90deg, rgba(51,180,76,1) 0%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 66%, rgba(217,32,39,1) 100%);',
        height: '5px',
        left: '0px',
        width: '100%',
      },
    },
  },

  sideBySide1: {
    flexDirection: ['column', '', 'row', 'row'],
    padding: ['2rem 1rem', '', '8vw 2rem 3vw'],
    '.content': {
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#500d0d',
      margin: ['-3rem auto 0rem', '', '0rem'],
      width: ['90%', '', '100%'],
      borderRadius: '252px 55px 104px 20px / 30px',
      order: ['', '', '1'],
    },
    '.title': {
      fontSize: '3.75rem',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'column',
      ':after': {
        content: "''",
        borderRadius: '252px 55px 104px 20px / 34px',
        background:
          'linear-gradient(90deg, rgba(51,180,76,1) 0%, rgba(255,255,255,1) 33%, rgba(255,255,255,1) 66%, rgba(217,32,39,1) 100%);',
        height: '5px',
        left: '0px',
        width: '100%',
      },
    },
    '.subtitle': {
      marginTop: '1rem',
      fontFamily: 'Roboto, san serif !important',
      fontWeight: '300',
      textTransform: 'uppercase',
      fontSize: '1.5rem',
    },
    '.lazyload-wrapper': {
      width: '100%',
      order: ['', '', '2'],
      padding: '1rem',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.content': {
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#500d0d',
      margin: ['-3rem auto 0rem', '', '0rem'],
      width: ['90%', '', '100%'],
      borderRadius: '252px 55px 104px 20px / 30px',
    },
    '.lazyload-wrapper': {
      width: '100%',
      padding: '1rem',
    },
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {},
  homepageHeroShout: {},

  homepageShout: {},

  // ? ==========================
  // ? =====  About Page  =======
  // ? ==========================
  aboutSection: {},

  aboutSliderHero: {},

  // ? ==========================
  // ? ======  Menu Page  =======
  // ? ==========================

  menu: {},

  // ? ==========================
  // ? =====  Gallery Page  =====
  // ? ==========================

  gallery: {},

  locationMap: {},

  // ? ==========================
  // ? =====  Events Page  ======
  // ? ==========================

  events: {},

  // ? ==========================
  // ? =====  Contact Page  =====
  // ? ==========================

  contactForm: {},

  locationMap: {},

  // ? ============================
  // ? ===  reservations Page  ====
  // ? ============================

  reservations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['3rem 1rem', '', '6rem 1rem'],
  },

  // ? ====================================
  // ? =====  Rotating Specials Page  =====
  // ? ====================================
}
